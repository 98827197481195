import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { IconGrid } from "./IconGrid";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons-symbol"
    }}>{`Icons Symbol`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <p><a parentName="p" {...{
        "href": "https://www.figma.com/file/nEzgNd0Y8mdLuaEIrj26rw/DS-3.0---Icons-%26-Images?type=design&node-id=1-15&mode=design&t=Wg4OXuG8JzL7V7fe-0"
      }}>{`Symbol icons can be found from following Figma link`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      